import { sendTrackingEvent, sendIntentEvent } from '../data_emitter';
import 'stylesheets/front/pages/top/index';

$(() => {
  // ホワイトペーパークリック時、preview_countをカウントアップ
  $('.content-file-item').on('click', function (_e) {
    $.ajax({
      type: 'POST',
      url:  $(this).data('countup-url'),
      data: {
        authenticity_token: $('meta[name="csrf-token"]').attr('content'),
      },
    });
    // GA送信
    sendIntentEvent(
      'content-file',
      `${window.location.pathname}#content?title=${$(this).find('.file-item-title').text()}`,
      'click',
    );
  });

  // 閲覧履歴
  $.ajax({
    url:      '/browsing_history',
    dataType: 'html',
  }).always(() => {
    $('#browsing-history-box').addClass('hide');
  }).done((data) => {
    $('#browsing-history-wrapper').children().remove();
    $('#browsing-history-wrapper').html(data);
    $('#browsing-history-wrapper .product-browsing-history-link').on('click', (e) => {
      const productName = $(e.currentTarget).data('productName');
      sendTrackingEvent('Browsing History', productName, 'click');
    });
  });

  // MEMO:: 一旦コメントアウト
  // AI
  // const linkOfTopSearchProduct = document.querySelector('#top-search-product-btn');
  // const linkOfSearchReviewCount = document.querySelector('#search-review-count-btn');
  // const aiDesc = document.querySelector('#ai-description');

  // if (linkOfSearchReviewCount) {
  //   linkOfSearchReviewCount.addEventListener('click', () => {
  //     aiDesc.classList.add('invisible');
  //   });
  // }

  // if (linkOfTopSearchProduct) {
  //   linkOfTopSearchProduct.addEventListener('click', () => {
  //     aiDesc.classList.remove('invisible');
  //   });
  // }
  // 週間レビュー獲得数ランキング
  fetch('/weekly_review_ranking')
    .then(response => response.text())
    .then(data => {
      document.getElementById('review-ranking-box').classList.add('hide');

      const reviewRankingWrapper = document.getElementById('review-ranking-wrapper');
      reviewRankingWrapper.innerHTML = '';
      reviewRankingWrapper.innerHTML = data;

      reviewRankingWrapper.querySelectorAll('li').forEach(li => {
        li.addEventListener('click', e => {
          const productName = e.currentTarget.getAttribute('data-product-name');
          const rank = e.currentTarget.getAttribute('data-rank');
          sendTrackingEvent('Weekly Ranking', `${rank} / ${productName}`, 'click');
        });
      });
    })
    .catch(error => {
      console.log(error); // eslint-disable-line no-console
    });

  // 週間製品ページ閲覧者ランキング
  fetch('/weekly_access_ranking')
    .then(response => response.text())
    .then(data => {
      document.getElementById('access-ranking-box').classList.add('hide');

      const accessRankingWrapper = document.getElementById('access-ranking-wrapper');
      accessRankingWrapper.innerHTML = '';
      accessRankingWrapper.innerHTML = data;

      accessRankingWrapper.querySelectorAll('li').forEach(li => {
        li.addEventListener('click', e => {
          const productName = e.currentTarget.getAttribute('data-product-name');
          const rank = e.currentTarget.getAttribute('data-rank');
          sendTrackingEvent('Weekly Ranking', `${rank} / ${productName}`, 'click');
        });
      });
    })
    .catch(error => {
      console.log(error); // eslint-disable-line no-console
    });

  // TODO:: 一旦コメントアウト。最新のdevelopを取り込んだのちに動作確認する
  // $('.security-check-list ').slick({
  //   arrows:           false,
  //   dots:             false,
  //   autoplay:         true,
  //   autoplaySpeed:    0,
  //   speed:            8000,
  //   cssEase:          'linear',
  //   slidesToShow:     1,
  //   variableWidth:    true,
  //   pauseOnFocus:     false,
  //   pauseOnHover:     false,
  //   pauseOnDotsHover: false,
  //   swipe:            false,
  //   swipeToSlide:     false,
  //   touchMove:        false,
  //   centerMode:       true,
  // });

  const searchCategoryHeading = document.querySelector('#searchCategoryHeading');
  // 親カテゴリーのクリックイベント
  document.querySelectorAll('.category-tab a[data-category-id]').forEach((element) => {
    element.addEventListener('click', () => {
      const categoryId = element.getAttribute('data-category-id');
      const parentCategoryList = document.querySelector('.parent-category-list');
      const subCategoryList = document.querySelector('.sub-category-list');
      const urlName = element.getAttribute('data-url-name');
      const name = element.getAttribute('data-name');

      // 全ての右側のブロックを非表示にする
      document.querySelectorAll('.category-tab-content').forEach((content) => {
        content.style.display = 'none';
      });
      // クリックしたカテゴリーの右側のブロックを表示する
      const urlNameElement = document.querySelector(`#${urlName}`);
      urlNameElement.style.display = 'block';
      urlNameElement.classList.add('fade-in');
      // 見出しをアップデート
      searchCategoryHeading.textContent = name;

      // 親カテゴリーのタブを非表示にする
      parentCategoryList.style.display = 'none';
      document.querySelectorAll('.sub-category-list > ul > div').forEach((subCategory) => {
        subCategory.style.display = 'none';
      });
      // クリックしたカテゴリーの子カテゴリーを表示する
      subCategoryList.style.display = 'block';
      const subCategoryElement = document.querySelector(`#sub-category-${categoryId}`);
      subCategoryElement.style.display = 'block';

      const secondLi = subCategoryElement.querySelectorAll('li')[1];
      secondLi.classList.remove('gray');
      secondLi.classList.add('bg-black');
      const secondLiAnchor = secondLi.querySelector('a');
      secondLiAnchor.classList.remove('c-black');
      secondLiAnchor.classList.add('c-white');
    });
  });

  // 子カテゴリーのクリックイベント(2層目)
  document.querySelectorAll('.category-tab a[data-sub-category-id]').forEach((element) => {
    element.addEventListener('click', () => {
      const urlName = element.getAttribute('data-url-name');
      const name = element.getAttribute('data-name');
      document.querySelectorAll('.category-tab-content').forEach((content) => {
        content.style.display = 'none';
      });

      // 既存のクラスを削除してから新しいクラスを追加する
      document.querySelectorAll('.sub-category-list > ul > div > li').forEach((subCategory) => {
        subCategory.classList.remove('bg-black');
        subCategory.classList.add('gray');
        const subCategoryAnchor = subCategory.querySelector('a');
        subCategoryAnchor.classList.remove('c-white');
        subCategoryAnchor.classList.add('c-black');
      });
      element.parentElement.classList.remove('gray');
      element.classList.remove('c-black');
      element.parentElement.classList.add('bg-black');
      element.classList.add('c-white');

      const categoryTabContent = document.querySelector(`#${urlName}`);
      categoryTabContent.style.display = 'block';
      categoryTabContent.classList.add('fade-in');
      searchCategoryHeading.textContent = name;
    });
  });

  // 親カテゴリーのタブのクリックイベント(2層目)
  document.querySelectorAll('.tab-top-category').forEach((tabTopCategory) => {
    tabTopCategory.addEventListener('click', () => {
      const urlName = tabTopCategory.getAttribute('data-url-name');
      const name = tabTopCategory.getAttribute('data-name');

      // 全ての右側のブロックを非表示にする
      document.querySelectorAll('.category-tab-content').forEach((content) => {
        content.style.display = 'none';
      });

      // 見出しをアップデート
      searchCategoryHeading.textContent = name;
      // クリックしたカテゴリーの右側のブロックを表示する
      const categoryTabContent = document.querySelector(`#${urlName}`);
      categoryTabContent.style.display = 'block';
      categoryTabContent.classList.add('fade-in');

      // 既存のクラスを削除してから新しいクラスを追加する
      document.querySelectorAll('.sub-category-list > ul > div > li').forEach((subCategory) => {
        subCategory.classList.remove('bg-black');
        subCategory.classList.add('gray');
        const subCategoryAnchor = subCategory.querySelector('a');
        subCategoryAnchor.classList.remove('c-white');
        subCategoryAnchor.classList.add('c-black');
      });
      tabTopCategory.parentElement.classList.remove('gray');
      tabTopCategory.classList.remove('c-black');
      tabTopCategory.parentElement.classList.add('bg-black');
      tabTopCategory.classList.add('c-white');
    });
  });

  // 戻るボタンのクリックイベント
  document.querySelectorAll('.back-button').forEach((element) => {
    element.addEventListener('click', () => {
      const subCategoryList = document.querySelector('.sub-category-list');
      const parentCategoryList = document.querySelector('.parent-category-list');
      const categoryTabContent = document.querySelectorAll('.category-tab-content');
      const popularCategory = document.querySelector('#popular-category');

      subCategoryList.style.display = 'none';
      parentCategoryList.style.display = 'block';
      searchCategoryHeading.textContent = '人気カテゴリー';

      categoryTabContent.forEach((content) => {
        content.style.display = 'none';
      });
      popularCategory.style.display = 'block';
      popularCategory.classList.add('fade-in');
      // 子カテゴリーのタブのクラスを初期化する(全てグレーボタンの黒文字)
      document.querySelectorAll('.sub-category-list > ul > div > li').forEach((subCategory) => {
        subCategory.classList.remove('bg-black');
        subCategory.classList.add('gray');
        const subCategoryAnchor = subCategory.querySelector('a');
        subCategoryAnchor.classList.remove('c-white');
        subCategoryAnchor.classList.add('c-black');
      });
    });
  });
});
